<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="姓名:">
            <el-input v-model="selectForm.name" placeholder="姓名"></el-input>
          </el-form-item>
          <el-form-item label="审核状态:">
            <el-input
              v-model="selectForm.state"
              placeholder="审核状态"
            ></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
   
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column label="姓名" prop="UserName"></el-table-column>
        <el-table-column label="文件名称" prop="FileName">
          <template slot-scope="scope">
            <el-button size="small" type="text">
              <a @click="linkDownload(scope.row.FileAddress)">{{
                scope.row.FileName
              }}</a>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" prop="ApprovalState">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.ApprovalState == '已审核'"
              type="success"
              disable-transitions
            >
              {{ scope.row.ApprovalState }}
            </el-tag>
            <el-tag
              v-else-if="scope.row.ApprovalState == '已退回'"
              type="danger"
              disable-transitions
            >
              {{ scope.row.ApprovalState }}
            </el-tag>
            <el-tag
              v-else-if="scope.row.ApprovalState == '待审核'"
              type="primary"
              disable-transitions
            >
              {{ scope.row.ApprovalState }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper ,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="operation ? '添加' : '编辑'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="100px"
      >
        <upload-files
          :files="fileList"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="1"
          :IsDisabled="false"
          :IsDeld="false"
        ></upload-files>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/UserChoosejob";
import UploadFiles from "../../components/UploadFiles";
import {
  CommitmentLetterGetInfoByWhere,
  CommitmentLetterRepulse,
  CommitmentLetterDelInfo,
  CommitmentLetterApproval,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose, UserChoosejob, UploadFiles },
  data() {
    return {
      loading: true,
      searchVal: "",
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      choosedusers: [],
      Intochoosedusers: [],
      operation: false, // true:新增, false:编辑
      addUserVisible: false,
      IntoUserVisible: false,
      buttonList: [],
      SakaryForm: {
        FileName: "",
        FileAddress: "",
      },
      selectForm: {
        name: "",
        state: "",
      },
      fileList: [{ FileName: "", URL: "" }],
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },

    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        state: this.selectForm.state,
        userName: this.selectForm.name,
      };

      CommitmentLetterGetInfoByWhere(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.SakaryForm = Object.assign({}, row);
      this.fileList = [
        {
          FileName: row.FileName,
          URL: row.FileAddress,
        },
      ];
      this.key += 1;
      this.SakaryVisible = true;
      this.operation = false;
    },
    handlePro() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要审核的一行数据！",
          type: "error",
        });
        return;
      }

      CommitmentLetterApproval({ id: row.Id }).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "审核成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleRe() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要退回的一行数据！",
          type: "error",
        });
        return;
      }

      CommitmentLetterRepulse({ id: row.Id }).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "退回成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData(false);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    linkDownload(url) {
      console.log(url);
      window.open(url, "_blank"); // 新窗口打开外链接
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

